import { AuthGuard } from './auth/auth.guard';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';

export const appRoutes: VexRoutes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: ['All'] }
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent)
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'empleados',
        loadComponent: () =>
          import('./pages/apps/empleados/empleados.component').then(
            (m) => m.EmpleadosComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['0'] } //'Administrador'
      }
    ]
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'imprentas',
        loadComponent: () =>
          import('./pages/apps/imprentas/imprentas.component').then(
            (m) => m.ImprentasComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['0', '1'] } //'Administrador','Recepcion'
      }
    ]
  },

  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'registros',
        children: [
          {
            path: 'instrucciones',
            loadComponent: () =>
              import('./pages/apps/registros/instrucciones/instrucciones.component').then(
                (m) => m.InstruccionesComponent
              ),
            canActivate: [AuthGuard],
            data: { roles: ['0'] } //'Administrador'
          },
          {
            path: 'card-size-cost',
            loadComponent: () =>
              import('./pages/apps/registros/card-size-cost/card-size-cost.component').then(
                (m) => m.CardSizeCostComponent
              ),
            canActivate: [AuthGuard],
            data: { roles: ['0'] } //'Administrador'
          },
          {
            path: 'destinos',
            loadComponent: () =>
              import('./pages/apps/registros/destinos/destinos.component').then(
                (m) => m.DestinosComponent
            ),
            canActivate: [AuthGuard],
            data: { roles: ['0', '1'] } //'Administrador','Recepcion'
          },
        ]
      }
    ]
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'ordenes',
        loadComponent: () =>
          import('./pages/apps/ordenes/ordenes.component').then(
            (m) => m.OrdenesComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['0', '1'] } //'Administrador','Recepcion'
      }
    ]
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'laminas',
        loadComponent: () =>
          import('./pages/apps/laminas/laminas.component').then(
            (m) => m.LaminasComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['0', '1', '5', '6'] } //'Administrador', 'Recepcion', 'Armado', 'CTP'
      }
    ]
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'almacen',
        loadComponent: () =>
          import('./pages/apps/almacen/almacen.component').then(
            (m) => m.AlmacenComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['0', '1'] } //'Administrador','Recepcion'
      }
    ]
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'envios',
        loadComponent: () =>
          import('./pages/apps/envios/envios.component').then(
            (m) => m.EnviosComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['0', '4'] } //'Administrador' 'Envios'
      }
    ]
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'pagos',
        loadComponent: () =>
          import('./pages/apps/pagos/pagos.component').then(
            (m) => m.PagosComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: ['0', '1', '4'] } //'Administrador','Recepcion', 'Envios'
      }
    ]
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'areas',
        children: [
          {
            path: 'monitoreo',
            loadComponent: () =>
              import('./pages/apps/areas/monitoreo/monitoreo.component').then(
                (m) => m.MonitoreoComponent
              ),
            canActivate: [AuthGuard],
            data: { roles: ['0', '2'] } //'Administrador','prensa'
          },
          {
            path: 'armado',
            loadComponent: () =>
              import('./pages/apps/areas/armado/armado.component').then(
                (m) => m.ArmadoComponent
              ),
            canActivate: [AuthGuard],
            data: { roles: ['0', '5'] } //'Administrador' , 'Armado'
          },
          {
            path: 'prensista',
            loadComponent: () =>
              import('./pages/apps/areas/prensista/prensista.component').then(
                (m) => m.PrensistaComponent
              ),
            canActivate: [AuthGuard],
            data: { roles: ['0', '2'] } //'Administrador','prensa'
          },
          {
            path: 'acabado',
            children: [
              {
                path: 'barniz-plastico',
                loadComponent: () =>
                  import('./pages/apps/areas/acabado/barniz-plastico/barniz-plastico.component').then(
                    (m) => m.BarnizPlasticoComponent
                  ),
                canActivate: [AuthGuard],
                data: { roles: ['0', '3'] } //'Administrador','acabado'
              },
              {
                path: 'corte',
                loadComponent: () =>
                  import('./pages/apps/areas/acabado/corte/corte.component').then(
                    (m) => m.CorteComponent
                  ),
                canActivate: [AuthGuard],
                data: { roles: ['0', '3'] } //'Administrador','acabado'
              }
            ]
          },
        ]
      }
    ]
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'logistica',
        children: [
          {
            path: 'bodega',
            loadComponent: () =>
              import('./pages/apps/logistica/bodega/bodega.component').then(
                (m) => m.BodegaComponent
              ),
            canActivate: [AuthGuard],
            data: { roles: ['0'] } //'Administrador',
          },
          {
            path: 'post-service',
            loadComponent: () =>
              import('./pages/apps/logistica/post-service/post-service.component').then(
                (m) => m.PostServiceComponent
              ),
            canActivate: [AuthGuard],
            data: { roles: ['0'] } //'Administrador',
          },
        ]
      }
    ]
  },
  {
    path: 'gestion',
    component: LayoutComponent,
    children: [
      {
        path: 'inventario',
        children: [
          {
            path: 'productos',
            loadComponent: () =>
              import('./pages/gestion/inventario/productos/productos.component').then(
                (m) => m.ProductosComponent
              ),
            canActivate: [AuthGuard],
            data: { roles: ['0','4'] } //'Administrador', 'Envios'
          },
          {
            path: 'entradas',
            loadComponent: () =>
              import('./pages/gestion/inventario/entradas/entradas.component').then(
                (m) => m.EntradasComponent
              ),
            canActivate: [AuthGuard],
            data: { roles: ['0'] } //'Administrador'
          },
          {
            path: 'salidas',
            loadComponent: () =>
              import('./pages/gestion/inventario/salidas/salidas.component').then(
                (m) => m.SalidasComponent
              ),
            canActivate: [AuthGuard],
            data: { roles: ['0'] } //'Administrador'
          }
        ]
       
      },
      {
        path: 'reports',
        children: [
          {
            path: 'report-ordenes',
            loadComponent: () =>
              import('./pages/gestion/reports/report-ordenes/report-ordenes.component').then(
                (m) => m.ReportOrdenesComponent
              ),
            canActivate: [AuthGuard],
            data: { roles: ['0'] } //'Administrador'
          },
          {
            path: 'report-tiempo-produccion',
            loadComponent: () =>
              import('./pages/gestion/reports/report-tiempo-produccion/report-tiempo-produccion.component').then(
                (m) => m.ReportTiempoProduccionComponent
              ),
            canActivate: [AuthGuard],
            data: { roles: ['0'] } //'Administrador'
          }
          // ,
          // {
          //   path: 'report-entrada-salida',
          //   loadComponent: () =>
          //     import('./pages/gestion/reports/report-entrada-salida/report-entrada-salida.component').then(
          //       (m) => m.ReportEntradaSalidaComponent
          //     ),
          //   canActivate: [AuthGuard],
          //   data: { roles: ['0'] } //'Administrador'
          // }
        ]
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/login'
  }
];
