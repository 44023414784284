<div class="vex-user-menu">
  <a (click)="close()" class="vex-user-menu-item" matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:account_circle"></mat-icon>
    <div class="vex-user-menu-item__label">Cambios de perfil</div>
  </a>

  <a (click)="close()" class="vex-user-menu-item" matRipple
    matRippleColor="rgb(var(--vex-color-primary-600), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:logout"></mat-icon>
    <div class="vex-user-menu-item__label">Cerrar sesión</div>
  </a>
</div>