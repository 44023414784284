import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { EncrDecrService } from '../services/encr-decr-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private EncrDecr: EncrDecrService
  ) { }

  rol = '';
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem('keylog')) {
      this.rol = this.EncrDecr.get(
        '123456$#@$^@1ERF',
        localStorage.getItem('log')
      );

      if (localStorage.getItem('token')) {
        let roles = next.data['roles'];

        if (this.rol == roles || roles.includes(this.rol)) {
          if (this.rol == '0') {
            //'Administrador'
            if (state.url == '/apps/empleados') {
              return true;
            } else if (state.url == '/apps/imprentas') {
              return true;
            } else if (state.url == '/apps/registros/instrucciones') { // nuevo
              return true;
            } else if (state.url == '/apps/registros/card-size-cost') { // NUEVO
              return true;
            } else if (state.url == '/apps/registros/destinos') {
              return true;
            } else if (state.url == '/apps/ordenes') {
              return true;
            } else if (state.url == '/apps/laminas') {
              return true;
            } else if (state.url == '/apps/almacen') {
              return true;
            } else if (state.url == '/apps/envios') {
              return true;
            } else if (state.url == '/apps/pagos') {
              return true;
            } else if (state.url == '/apps/areas/monitoreo') {
              return true;
            } else if (state.url == '/apps/areas/armado') {
              return true;
            } else if (state.url == '/apps/areas/prensista') {
              return true;
            } else if (state.url == '/apps/areas/acabado/barniz-plastico') {
              return true;
            } else if (state.url == '/apps/areas/acabado/corte') {
              return true;
            } else if (state.url == '/gestion/inventario/productos') {
              return true;
            } else if (state.url == '/gestion/inventario/entradas') {
              return true;
            } else if (state.url == '/gestion/inventario/salidas') {
              return true;
            } else if (state.url == '/gestion/reports/report-ordenes') {
              return true;
            }else if (state.url == '/gestion/reports/report-tiempo-produccion') {
              return true;
            }
          }

          if (this.rol == '1') {
            //'Recepcion'
            if (state.url == '/apps/imprentas') {
              return true;
            } else if (state.url == '/apps/destinos') {
              return true;
            } else if (state.url == '/apps/ordenes') {
              return true;
            } else if (state.url == '/apps/laminas') {
              return true;
            } else if (state.url == '/apps/almacen') {
              return true;
            } else if (state.url == '/apps/pagos') {
              return true;
            }
          }

          if (this.rol == '2') {
            //'Prensa'
            if (state.url == '/apps/areas/prensista') {
              return true;
            }
          }

          if (this.rol == '3') {
            //'Acabado'
            if (state.url == '/apps/areas/acabado/barniz-plastico') {
              return true;
            } else if (state.url == '/apps/areas/acabado/corte') {
              return true;
            }
          }

          if (this.rol == '4') {
            //'Envios'
            if (state.url == '/apps/envios') {
              return true;
            } else if (state.url == '/apps/pagos') {
              return true;
            } else if (state.url == '/gestion/inventario/productos') {
              return true;
            }
          }
          
          if (this.rol == '5') {
            //'Armado'
            if (state.url == '/apps/areas/armado') {
              return true;
            }
          }

        } else if (roles == 'All') {
          return true;
        } else if (roles != this.rol) {
          localStorage.clear();
          this.router.navigate(['/login']);
          return false;
        }
      } else {
        this.router.navigate(['/login']);
        localStorage.clear();
        return false;
      }
    }
    this.router.navigate(['/login']);
    return false;
  }
}